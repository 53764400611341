import React from 'react';

const PeopleQuestion: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.4375 5.875C7.02227 5.875 5.875 7.02227 5.875 8.4375C5.875 9.85273 7.02227 11 8.4375 11C9.85273 11 11 9.85273 11 8.4375C11 7.02227 9.85273 5.875 8.4375 5.875ZM3.875 8.4375C3.875 5.9177 5.9177 3.875 8.4375 3.875C10.9573 3.875 13 5.9177 13 8.4375C13 10.9573 10.9573 13 8.4375 13C5.9177 13 3.875 10.9573 3.875 8.4375Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.4375 15.625C6.05704 15.625 4.31325 16.072 3.2007 16.4907C2.54538 16.7369 2.125 17.3567 2.125 18.037V20.75H14.75V18.037C14.75 17.3564 14.3304 16.737 13.6746 16.4904M2.49682 14.6187C3.81934 14.121 5.80106 13.625 8.4375 13.625C11.0744 13.625 13.0562 14.1212 14.3785 14.6183C15.8186 15.1599 16.75 16.5284 16.75 18.037V22.75H0.125V18.037C0.125 16.5292 1.05654 15.16 2.49682 14.6187ZM8.4375 15.625C10.8179 15.625 12.5618 16.072 13.6746 16.4904L8.4375 15.625Z"
      />
      <path d="M19.5 15C19.7761 15 20 14.7761 20 14.5C20 14.2239 19.7761 14 19.5 14C19.2239 14 19 14.2239 19 14.5C19 14.7761 19.2239 15 19.5 15Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 15C19.7761 15 20 14.7761 20 14.5C20 14.2239 19.7761 14 19.5 14C19.2239 14 19 14.2239 19 14.5C19 14.7761 19.2239 15 19.5 15ZM18 14.5C18 13.6716 18.6716 13 19.5 13C20.3284 13 21 13.6716 21 14.5C21 15.3284 20.3284 16 19.5 16C18.6716 16 18 15.3284 18 14.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0237 3.26968C19.1696 3.18942 18.1616 3.35596 17.2796 3.74618L16.3651 4.15079L15.5559 2.3218L16.4704 1.9172C17.6438 1.39804 18.9936 1.16408 20.2108 1.27845C21.4037 1.39053 22.6637 1.86475 23.3506 2.97601C24.6391 5.06041 23.6012 7.29813 21.8717 8.76455C21.1605 9.36759 20.8436 9.80853 20.6885 10.1474C20.5388 10.4744 20.5 10.7897 20.5 11.2501V12.2501H18.5V11.2501C18.5 10.6724 18.5459 10.023 18.8699 9.31513C19.1884 8.6191 19.734 7.95492 20.5783 7.23908C21.9598 6.06775 22.1797 4.88546 21.6494 4.02762C21.4269 3.66775 20.9022 3.35222 20.0237 3.26968Z"
      />
    </svg>
  );
};

export default PeopleQuestion;
